import React, { Component } from 'react'
import { List, Card,Icon ,Avatar} from 'antd';
import './MainPage.css';
import { withRouter} from 'react-router-dom';


import firebase_init from '../../Service/firebase'

const FirebaseDatabase = firebase_init.database();
const S3BucketURL = "https://keke-video.s3.amazonaws.com/"

class MainPage extends Component {
    constructor(props) {
        super(props);
        this.state = { };
      
        this.navigate=this.navigate.bind(this);
        this.navigate2EditSub=this.navigate2EditSub.bind(this)
    }



    componentDidMount() {
      

    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
    
        
      }

    navigate=(item)=>()=>{
           
            this.props.history.push('/videoview/'+item.key)
    }

    navigate2EditSub=(item)=>()=>{
           
        this.props.history.push('/editor/'+item.key)
    }

    render() {
        return (
            <div className="MainPage">
            <div className="CategoryTitle">Mới</div>
                <List
                    grid={{ gutter: 40, xs: 2, sm: 2, md: 4, lg: 4, xl: 6, xxl: 5 }}
                    dataSource={this.props.videoList}
                    renderItem={item => (
                        <List.Item>
                            <Card hoverable
                                cover={<img alt="example" src={S3BucketURL + item.ThumbnailFilename} />}
                                
                            >
                                <Card.Meta
                                   avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                                    title={item.VideoFilename}
                                    description="25 luot xem"
                                    onClick={this.navigate(item)}
                                />
                                
                            </Card>
                        </List.Item>
                    )}
                />
            </div>
        )
    }
}

export default withRouter(MainPage)
