import React, { Component } from 'react'

import { Player } from 'video-react';
import { List, Button, Input, Icon, Select } from 'antd'


import firebase_init from '../../Service/firebase'

import './VideoView.css'
let uid = require('uid')

const Option = Select.Option;


const FirebaseDatabase = firebase_init.database();
const S3BucketURL = "https://keke-video.s3.amazonaws.com/"


export default class VideoView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTime: 0,
            subList: {},
            subListID: [],
            currentSub: null,
            language: "Vietnamese",
            videoData: null
        }
        this.handleStateChange = this.handleStateChange.bind(this);

        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.setVideoData = this.setVideoData.bind(this)

    }

    setVideoData(data) {
        if (data.Subtitles) {
            let subList = data.Subtitles.subList;
            let subListID = []
            for (var key in subList) {
                if (subList.hasOwnProperty(key)) {
                    subListID.push(key)
                }
            }
            this.setState({ videoData: data, subList, subListID })
        }
        else
            this.setState({ videoData: data })
    }


    componentDidMount() {
        // subscribe state change
        this.refs.player.subscribeToStateChange(this.handleStateChange);
        var videokey = this.props.match.params.videokey;
        var setVideoData = this.setVideoData;
        var sentenceData = FirebaseDatabase.ref('Videos/').once('value').then(function (snapshot) {
            snapshot.forEach(function (childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                if (childKey === videokey) {
                    childData.key = childKey;
                    setVideoData(childData)
                }


            });
        })
        //    console.log("this.props.match.params.videoname);
    }
    handleStateChange(state, prevState) {
        // copy player state to this component's state
        let subList = this.state.subList;
        let currentSub;
        for (var key in subList) {
            if (subList.hasOwnProperty(key)) {
                if ((state.currentTime >= subList[key].timeStart) && (state.currentTime <= subList[key].timeEnd)) {
                    currentSub = subList[key]
                }
            }
        }


        this.setState({
            player: state,
            currentTime: state.currentTime,
            currentSub

        });
    }

    handleLanguageChange(value) {
        this.setState({ language: value })
    }







    render() {
        let currentTime = this.state.currentTime;


        return (
            <div className="VideoViewContainer">
                <div className="VideoPlayer">

                    <Player ref="player" fluid={false} width={900} aspectRatio={"16:9"} >
                        {this.state.videoData ?
                            <source src={S3BucketURL + this.state.videoData.VideoFilename} />
                            : null}
                        {this.state.currentSub ?
                            <div className="SubShow">{this.state.currentSub.text}</div> :
                            null
                        }
                    </Player>


                    {this.state.currentSub ?
                        <div className="SubShow">{this.state.currentSub.text}</div> :
                        null
                    }

                </div>
                <div style={{ margin: 10 }}>
                    <Select style={{ width: 120 }} defaultValue="Vietnamese" onChange={this.handleLanguageChange} >
                        <Option value="Vietnamese">Vietnamese</Option>
                        <Option value="Tibetan">Tibetan</Option>
                        <Option value="Chinese">Chinese</Option>
                        <Option value="English">English</Option>
                        <Option value="Korean">Korean</Option>
                        <Option value="Thailand">Thailand</Option>
                        <Option value="Cambodian">Cambodian</Option>
                        <Option value="Russian">Russian</Option>
                        <Option value="Sanskit">Sanskit</Option>


                    </Select>

                </div>

            </div>
        )
    }
}
