import React, { Component } from 'react';
import logo from './logo.svg';


import './App.css';
import 'video-react/dist/video-react.css';

import { withRouter, Route, Switch, Redirect } from 'react-router-dom';



//Redux component
import { connect } from 'react-redux'
import { checkLogged_and_Login_automatically } from './Service/Redux/service'
import { bindActionCreators } from 'redux';


import { Layout } from 'antd'
import Editor from './Page/Editor/Editor'
import Header from './Page/Header/Header'
import MainPage from './Page/MainPage/MainPage'
import VideoView from './Page/VideoView/VideoView'
import SideMenu from './Page/SideMenu/SideMenu'


import firebase_init from './Service/firebase'

const FirebaseDatabase = firebase_init.database();
const S3BucketURL = "https://keke-video.s3.amazonaws.com/"

class App extends Component {

  constructor(props){
    super(props);
    this.state={searchKey:null,
                isSearching:false,
                videoList: [] };
    this.setVideoList = this.setVideoList.bind(this);
  }

  componentDidMount() {
    this.props.checkLogged_and_Login_automatically();
    let data = [];
    var setVideoList = this.setVideoList;
    var sentenceData = FirebaseDatabase.ref('Videos/').once('value').then(function (snapshot) {
        snapshot.forEach(function (childSnapshot) {
            var childKey = childSnapshot.key;
            var childData = childSnapshot.val();
            childData.key=childKey;
            data.push(childData);


        });
        setVideoList(data);
    })
  }
  setVideoList(data) {
    this.setState({ videoList: data })
}
  searchForTitle=(key)=>{
 
   this.setState({searchKey:key,isSearching:true});
   let data = [];
   var setVideoList = this.setVideoList;
   var sentenceData = FirebaseDatabase.ref('Videos/').once('value').then(function (snapshot) {
       snapshot.forEach(function (childSnapshot) {
           var childKey = childSnapshot.key;
           var childData = childSnapshot.val();
           childData.key=childKey;
           if (childData.VideoFilename.includes(key))
           {
               console.log(childData)
             data.push(childData);
           }


       });
       setVideoList(data);
      })
  }

  render() {
    return (
      <div className="App">
        <Layout>

          <Header userInfo={this.props.userInfo} searchForTitle={this.searchForTitle} />
          <Layout>
           <Layout.Sider> 
                <SideMenu/> 
              </Layout.Sider>
            <Layout.Content>
            
              <Switch>
                <Route exact path='/' component={() => <MainPage videoList={this.state.videoList} searchKey={this.state.searchKey} isSearching={this.state.isSearching}/>} />
                <Route path='/editor/:videokey' component={Editor} />
                <Route path='/videoview/:videokey' component={VideoView} />


                <Redirect to="/404" />
              </Switch>
            </Layout.Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

function mapState2Props(state) {
  return {
    userInfo: state.accountReducer.userInfo,


  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    checkLogged_and_Login_automatically,


  }, dispatch)

}

export default withRouter(connect(mapState2Props, mapDispatchToProps)(App));
