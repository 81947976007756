import { createStore,combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'



//ACtions Import
import {
   
    SET_USER_INFO_AFTER_LOGIN,
   
    
    SIGNOUT,
    
    } from './actions' ;





export const DefaultAccountState = {
    isCheckingLoginInfo:false,
    isCheckingSignUpInfo:false, 
    isCompleteSignUpSuccessfully:false,

    isGettingUserInfo:false,
    num: 1,
    userInfo:null
   
   
}



export const accountReducer = (state = DefaultAccountState , action) => {
    let count_temp=0;
    switch (action.type) {
        case SET_USER_INFO_AFTER_LOGIN: return {...state, userInfo:action.userInfo}
        case SIGNOUT:return{...state,userInfo:null};

        default: return state;
    }
}


export const rootReducer = combineReducers({
    accountReducer,
   
    router: routerReducer
})
