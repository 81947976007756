import React, { Component } from 'react'

import { Player } from 'video-react';
import { List, Button, Input, Icon, Select } from 'antd'


import firebase_init from '../../Service/firebase'

import './Editor.css'
let uid = require('uid')

const Option = Select.Option;


const FirebaseDatabase = firebase_init.database();
const S3BucketURL = "https://keke-video.s3.amazonaws.com/"


export default class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTime: 0,
            subList: {},
            subListID: [],
            currentSub: null,
            language: "Vietnamese",
            videoData: null
        }
        this.handleStateChange = this.handleStateChange.bind(this);
        this.addSubtitle = this.addSubtitle.bind(this);
        this.onSubTextChange = this.onSubTextChange.bind(this);
        this.deleteSub = this.deleteSub.bind(this);
        this.getTime = this.getTime.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.setVideoData=this.setVideoData.bind(this)
        this.saveSub=this.saveSub.bind(this)
    }

    setVideoData(data) {
        if (data.Subtitles){
            let subList=data.Subtitles.subList;
            let subListID=[]
            for (var key in subList) {
                if (subList.hasOwnProperty(key)) {
                   subListID.push(key)
                }
            }
            this.setState({videoData:data,subList,subListID})
        }
        else
        this.setState({ videoData: data })
    }

    saveSub(){
        
        FirebaseDatabase.ref("Videos/" + this.state.videoData.key+"/Subtitles").set({
                language:this.state.language,
                subList:this.state.subList
          })
    }

    componentDidMount() {
        // subscribe state change
        this.refs.player.subscribeToStateChange(this.handleStateChange);
        var videokey = this.props.match.params.videokey;
        var setVideoData = this.setVideoData;
        var sentenceData = FirebaseDatabase.ref('Videos/').once('value').then(function (snapshot) {
            snapshot.forEach(function (childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                if (childKey === videokey) {
                    childData.key = childKey;
                    setVideoData(childData)
                }


            });
        })
        //    console.log("this.props.match.params.videoname);
    }
    handleStateChange(state, prevState) {
        // copy player state to this component's state
        let subList = this.state.subList;
        let currentSub;
        for (var key in subList) {
            if (subList.hasOwnProperty(key)) {
                if ((state.currentTime >= subList[key].timeStart) && (state.currentTime <= subList[key].timeEnd)) {
                    currentSub = subList[key]
                }
            }
        }


        this.setState({
            player: state,
            currentTime: state.currentTime,
            currentSub

        });
    }

    handleLanguageChange(value) {
        this.setState({ language: value })
    }

    addSubtitle() {
        let subList = this.state.subList;




        let id = uid(5);
        subList[id] = { timeStart: this.state.currentTime, timeEnd: this.state.currentTime, text: "", id };

        let subListID = this.state.subListID;
        subListID.push(id);

        this.setState({ subList, subListID });


    }


    scrollToBottom() {
        const scrollHeight = this.sublistContainer.scrollHeight;
        const height = this.sublistContainer.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.sublistContainer.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    onSubTextChange(id, e) {
        let subList = this.state.subList;

        subList[id] = { timeStart: this.state.subList[id].timeStart, timeEnd: this.state.subList[id].timeEnd, text: e.target.value, id };
        this.setState({ subList })

    }

    deleteSub = (id) => () => {
        let subList = this.state.subList;
        delete (subList[id]);

        let subListID = this.state.subListID;


        subListID.splice(subListID.indexOf(id), 1);

        this.setState({ subList, subListID })
    }

    getTime = (id, type) => () => {
        let subList = this.state.subList;
        if (type === "start")
            subList[id] = {
                timeStart: this.state.currentTime,
                timeEnd: this.state.subList[id].timeEnd,
                text: this.state.subList[id].text,
                id
            };
        if (type === "end") {
            subList[id] = {
                timeStart: this.state.subList[id].timeStart,
                timeEnd: this.state.currentTime,
                text: this.state.subList[id].text,
                id
            };
        }
        this.setState({ subList })


    }

    render() {
        let currentTime = this.state.currentTime;


        return (
            <div className="EditorContainer">
                <div className="VideoPlayer">

                    <Player ref="player" fluid={false} height={500} aspectRatio={"4:3"} >
                        {this.state.videoData ?
                            <source src={S3BucketURL+this.state.videoData.VideoFilename}/>
                            : null}
                    </Player>


                    {this.state.currentSub ?
                        <div className="SubShow">{this.state.currentSub.text}</div> :
                        null
                    }

                </div>
                <div style={{margin:10}}>
                    <Select style={{ width: 120 }} defaultValue="Vietnamese" onChange={this.handleLanguageChange} >
                        <Option value="Vietnamese">Vietnamese</Option>
                        <Option value="Tibetan">Tibetan</Option>
                        <Option value="Chinese">Chinese</Option>
                        <Option value="English">English</Option>
                        <Option value="Korean">Korean</Option>
                        <Option value="Thailand">Thailand</Option>
                        <Option value="Cambodian">Cambodian</Option>
                        <Option value="Russian">Russian</Option>
                        <Option value="Sanskit">Sanskit</Option>


                    </Select>
                    <Button type="primary" size="default" onClick={this.saveSub}>Save</Button>
                    <div className="SubListContainer" ref={sublistContainer => { this.sublistContainer = sublistContainer; }} >
                        <List
                            itemLayout="horizontal"
                            dataSource={this.state.subListID}
                            renderItem={item => (
                                <List.Item>
                                    <Input addonBefore={<Icon type="clock-circle" onClick={this.getTime(item, "start")} />} value={this.state.subList[item].timeStart} />
                                    <Input addonBefore={<Icon type="clock-circle" onClick={this.getTime(item, "end")} />} value={this.state.subList[item].timeEnd} />
                                    <Input value={this.state.subList[item].text} onChange={this.onSubTextChange.bind(this, item)} />
                                    <Button type="primary" shape="square" icon="close" size="default" onClick={this.deleteSub(item)} />
                                </List.Item>
                            )}
                        />
                        <Button type="primary" icon="plus" onClick={this.addSubtitle}></Button>
                    </div>
                </div>

            </div>
        )
    }
}
