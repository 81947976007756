import React, { Component } from 'react'
import { Menu, Icon } from 'antd';
import './SideMenu.css'
const SubMenu=Menu.SubMenu;
export default class SideMenu extends Component {
    render() {
        return (
            <div className="SideMenuContainer">
                <Menu
                    style={{ width: 256 }}
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    mode='inline'
                    theme='light'
                >
                    <Menu.Item key="1">
                        <Icon type="mail" />
                        Mới
                      </Menu.Item>
                    <Menu.Item key="2">
                        <Icon type="calendar" />
                        Nổi bật
                      </Menu.Item>
               
                    <SubMenu key="sub2" title={<span><Icon type="calendar" /><span>Lưu trữ</span></span>}>
                        <Menu.Item key="7">Lịch sử</Menu.Item>
                        <Menu.Item key="8">Đã thích</Menu.Item>
                    
                    </SubMenu>
                    <Menu.Item key="4">
                        <Icon type="setting" />
                      Cài đặt
                      </Menu.Item>
                </Menu>
            </div>
        )
    }
}
