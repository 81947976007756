
export const SIGNOUT="SIGNOUT";



export const SET_USER_INFO_AFTER_LOGIN="SET_USER_INFO_AFTER_LOGIN";






////////////// LOGIN SIGNUP action //////////////////////

//LOGIN



// if success then set userinfo to store
export function setUserInfoAfterLogin(userInfo) {
        return {
            type: SET_USER_INFO_AFTER_LOGIN,
            userInfo
        };
    } 
    
    

//SIGNOUT

export function signout(){
        return {
            type:SIGNOUT
        }
    }
    