import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';


///redux
import thunkMiddleware from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';

import { ConnectedRouter, routerReducer, routerMiddleware, push } from 'react-router-redux'

import { rootReducer } from './Service/Redux/reducer';



const history = createHistory()
const middleware = routerMiddleware(history)
const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(middleware, thunkMiddleware),
        window.devToolsExtension ? window.devToolsExtension() : f => f,
    )
)




ReactDOM.render(<Provider store={store}>
                     <ConnectedRouter history={history}>
                           <App />

                  </ConnectedRouter>
                </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
