// Initialize Firebase
import firebase from 'firebase'
require('firebase/firestore')

var config = {
  apiKey: "AIzaSyB8T98U02_pVje_VFp2SczIbfSGFZ7TInQ",
  authDomain: "keke-project.firebaseapp.com",
  databaseURL: "https://keke-project.firebaseio.com",
  projectId: "keke-project",
  storageBucket: "keke-project.appspot.com",
  messagingSenderId: "309843587242"
};
var firebase_init = firebase.initializeApp(config);
export const Authprovider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase_init;