import { Layout, Menu, Breadcrumb, Icon, Button, Avatar, Modal, Upload, message, Progress, Input } from 'antd';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import axios from 'axios'
import firebase_init from '../../Service/firebase'


import { loginGG } from '../../Service/Redux/service'
import './Header.css'


//Redux component
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux'

var aws = require('aws-sdk');
const Search = Input.Search;

aws.config.update({
  accessKeyId: "AKIAJI5XFBRIQLGJKVGQ",
  secretAccessKey: "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"
});

const FirebaseDatabase = firebase_init.database();

class Header extends Component {

  constructor(props) {
    /* ... */
    super(props);
    this.state = {
      UploadModalVisible: false,
      user: null,// <-- add this line,
      uploadPercent: 0
    }
    this.login = this.login.bind(this);
    this.handleUploadModalOk = this.handleUploadModalOk.bind(this);
    this.openUploadModal = this.openUploadModal.bind(this);
    this._onDrop = this._onDrop.bind(this);
    this.setUploadPercentage = this.setUploadPercentage.bind(this)
  }
  login() {
    this.props.loginGG();
  }


  // route to this path

  setUploadPercentage(per) {
    this.setState({ uploadPercent: per })
  }
  handleUploadModalOk() {
    this.setState({ UploadModalVisible: false })
  }
  openUploadModal() {
    this.setState({ UploadModalVisible: true })
  }


  _onDrop(files) {

    var file = files[0];
    console.log(file.name);
    var filename = file.name.replace(/\s+/g, '');
    var s3 = new aws.S3();

    var params = {
      Bucket: "keke-video",
      Key: filename,
      Expires: 60,
      ContentType: file.type,

    };

    var userInfo = this.props.userInfo;
    var setUploadPercentage = this.setUploadPercentage;
    s3.getSignedUrl("putObject", params, function (err, data) {
      if (err) {
        console.log(err);
        return err;
      } else {


        console.log(data)
        var signedUrl = data;

        var options = {
          headers: {
            'Content-Type': file.type,
            'Access-Control-Allow-Origin': '*',


          },
          onUploadProgress: progressEvent => setUploadPercentage(progressEvent.loaded / file.size * 100)
        };

        axios.put(signedUrl, file, options).then(function (result) {
          console.log(result);
          setUploadPercentage(0);
          var videosRef = FirebaseDatabase.ref("Videos");

          videosRef.push({
            VideoFilename: filename,

            UID: userInfo.providerData[0].uid,
            displayName: userInfo.providerData[0].displayName

          });

        })
          .catch(function (err) {
            console.log(err);
          });


      }
    });


  }



  render() {


    return (
      <Layout.Header>
        <Modal
          title="Tải video lên"
          visible={this.state.UploadModalVisible}
          onOk={this.handleUploadModalOk}
          onCancel={this.handleUploadModalOk}
        >
          <Dropzone
            accept="video/*"
            onDrop={this._onDrop} size={150}>
            <div>
              Drop some files here!
          </div>
          </Dropzone>
          {this.state.uploadPercent > 0 ?
            <Progress percent={this.state.uploadPercent} />
            : null}
        </Modal>
        <a href="/" > KEKE</a>
        <Search
          placeholder="Bạn muốn tìm kiếm gì"
          onSearch={value => this.props.searchForTitle(value)}
          enterButton
          style={{ width: 400 }}
        />
        {
          this.props.userInfo ?
            //check whether render menu for school client or problem owner
            <div className="HeaderAvatar">

              <Button onClick={this.openUploadModal}>
                <Icon type="upload" /> Upload
                </Button>

              <Avatar size="large" icon="user" />
            </div>
            :
            <Button onClick={this.login} >SIGN IN</Button>
        }
      </Layout.Header >
    );
  }
}




const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    loginGG,




  }, dispatch)

}

export default connect(null, mapDispatchToProps)(Header);