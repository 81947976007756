import firebase_init, { auth, Authprovider, firestore } from '../firebase.js';



import {

    setUserInfoAfterLogin,
   
    signout,
 
} from './actions';




const AccountData_Table = "AccountData";

export function loginGG() {
    return (dispatch) => {
        auth.signInWithPopup(Authprovider)
            .then((result) => {
                const user = result.user;
                dispatch(setUserInfoAfterLogin(user));

                firestore.collection(AccountData_Table).doc(user.uid).get().then((doc) => {
                    if (doc.exists) {
                        console.log(doc)
                    }
                })
            });

    }
}

export function checkLogged_and_Login_automatically() {
    return (dispatch) => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                dispatch(setUserInfoAfterLogin(user))
               
            }
        });
    }
}